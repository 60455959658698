<template>
  <div class="modifyPaperBg">
    <div class="modifyPaperCenter">
      <div class="modifyLeft" v-if="courseworkId">
        <p>
          <span>姓名：</span>
          <span class="modifyName">{{ studentWorkDetail.name?studentWorkDetail.name:studentWorkDetail.telNumber }}</span>
        </p>
        <p>学号：{{ studentWorkDetail.studentNumber }}</p>
        <p>提交时间：{{ studentWorkDetail.submitTime }}</p>
<!--        <p>测试得分：10分</p>-->
      </div>
      <div class="modifyLeft" v-if="celerityCheckExamId || totalScore">
        <p>
          <span>姓名：</span>
          <span class="modifyName">{{ studentExamDetail.name?studentExamDetail.name:studentExamDetail.telNumber }}</span>
        </p>
        <p>学号：{{ studentExamDetail.studentNumber }}</p>
        <template  v-if="CheckExaminationDetailArr&&CheckExaminationDetailArr.length">
          <p>提交时间：{{studentExamDetail.examPapersStudentInfo.answerEndTime}}</p>
        </template>
        <p v-if="progressType=='AR'">考试得分：{{totalScore}}分</p>
      </div>
      <div class="modifyRight">
        <div>
          <p class="modifyHeadline" v-if="courseworkId">{{ workDetail.name }}</p>
          <p class="modifyHeadline" v-if="!courseworkId">{{ examPapersTestCenterName}}</p>
        </div>
        <div class="questionFlex">
          <p
              v-if="courseworkId"
              class="questionFlexP"
              v-for="(item, index) in question"
              :key="index"
              @click="changeQuestion(index, item.type)"
              :class="questionShow == index ? 'questionActive' : ''"
          >
            {{ item.name }}
          </p>
          <p  v-if="!courseworkId"
              class="questionFlexP"
              v-for="(item, index) in vocationalQuestion"
              :key="index"
              @click="changeQuestion(index, item.type)"
              :class="questionShow == index ? 'questionActive' : ''"
          >
            {{ item.name }}
          </p>
        </div>
        <div class="topicOveflowm">
<!--          作业-->
          <div v-if="courseworkId" v-for="(item,index) in stdDetailArr" :key="index">
            <!-- 选择题 -->
            <exercises-s-c
                ref="std"
                v-if="item.workType === 'SC'"
                v-show="['all', 'SC'].includes(showType)"
                :exercisesInfo="item"
                :openType="3"
                :exercisesListIndex="index+1"
                :isShowRightAndAnalysis="true"
                :isShowTeacherCorrection="true"
                :isShowTeacherMCDecide="true"
            ></exercises-s-c>
            <exercises-m-c
                ref="std"
                v-if="item.workType === 'MC'"
                v-show="['all', 'MC'].includes(showType)"
                :exercisesInfo="item"
                :openType="3"
                :exercisesListIndex="index+1"
                :isShowRightAndAnalysis="true"
                :isShowTeacherCorrection="true"
                :isShowTeacherMCDecide="true"
            ></exercises-m-c>
            <!-- 填空题 -->
            <exercises-f-b
                ref="std"
                v-if="item.workType === 'FB'"
                v-show="['all', 'FB'].includes(showType)"
                :exercisesInfo="item"
                :openType="3"
                :exercisesListIndex="index + 1"
                :isShowRightAndAnalysis="true"
                :isShowTeacherCorrection="true"
                :isShowTeacherDecide="true"
            >
            </exercises-f-b>
            <!-- 判断题 -->
            <exercises-t-f
                ref="std"
                v-if="item.workType === 'TF'"
                v-show="['all', 'TF'].includes(showType)"
                :exercises-info="item"
                :open-type="3"
                :exercisesListIndex="index+1"
                :isShowRightAndAnalysis="true"
                :isShowTeacherCorrection="true"
                :isShowTeacherMCDecide="true"
            ></exercises-t-f>
            <!-- 简答题 -->
            <exercises-s-a-q
                ref="std"
                v-else-if="item.workType === 'SAQ'"
                v-show="['all', 'SAQ'].includes(showType)"
                :exercisesInfo="item"
                :openType="3"
                :exercisesListIndex="index+1"
                :isShowRightAndAnalysis="true"
                :isShowTeacherCorrection="true"
                :isShowTeacherMCDecide="true"
            >
            </exercises-s-a-q>
          </div>
<!--          试卷-->
          <div class="examModifyPaper" v-if="celerityCheckExamId || totalScore" v-for="(item,index) in CheckExaminationDetailArr" :key="index">
            <!-- 选择题 -->
            <exercises-s-c
                ref="std"
                v-if="item.exercisesType === 'SC'"
                v-show="['all', 'SC'].includes(showType)"
                :exercisesInfo="item"
                :openType="4"
                :exercisesListIndex="index+1"
                :isShowRightAndAnalysis="true"
                :isShowTeacherCorrection="true"
                :isShowTeacherMCDecide="true"
                :isShowTeacherMCDecideScore="true"
            ></exercises-s-c>
            <exercises-m-c
                ref="std"
                v-if="item.exercisesType === 'MC'"
                v-show="['all', 'MC'].includes(showType)"
                :exercisesInfo="item"
                :openType="4"
                :exercisesListIndex="index+1"
                :isShowRightAndAnalysis="true"
                :isShowTeacherCorrection="true"
                :isShowTeacherMCDecide="true"
                :isShowTeacherMCDecideScore="true"
            ></exercises-m-c>
            <!-- 填空题 -->
            <exercises-f-b
                ref="std"
                v-if="item.exercisesType === 'FB'"
                v-show="['all', 'FB'].includes(showType)"
                :exercisesInfo="item"
                :openType="4"
                :exercisesListIndex="index + 1"
                :isShowRightAndAnalysis="true"
                :isShowTeacherCorrection="true"
                :isShowTeacherDecide="true"
                :isShowTeacherMCDecideScore="true"
            >
            </exercises-f-b>
            <!-- 判断题 -->
            <exercises-t-f
                ref="std"
                v-if="item.exercisesType === 'TF'"
                v-show="['all', 'TF'].includes(showType)"
                :exercises-info="item"
                :open-type="4"
                :exercisesListIndex="index+1"
                :isShowRightAndAnalysis="true"
                :isShowTeacherCorrection="true"
                :isShowTeacherMCDecideScore="true"
                :isShowTeacherMCDecide="true"
            ></exercises-t-f>
            <!-- 简答题 -->
            <exercises-s-a-q
                ref="std"
                v-else-if="item.exercisesType === 'SAQ'"
                v-show="['all', 'SAQ'].includes(showType)"
                :exercisesInfo="item"
                :openType="4"
                :exercisesListIndex="index+1"
                :isShowRightAndAnalysis="true"
                :isShowTeacherCorrection="true"
                :isShowTeacherMCDecide="true"
                :isShowTeacherMCDecideScore="true"
            >
            </exercises-s-a-q>
<!--            完形填空-->
            <exercises-c-t
              ref="std"
              v-else-if="item.exercisesType === 'RC'"
              v-show="['all','RC'].includes(showType)"
              :exercisesInfo="item"
              :openType="4"
              :exercisesListIndex="index+1">

            </exercises-c-t>
            <exercises-c-t
                ref="std"
                v-else-if="item.exercisesType === 'CT'"
                v-show="['all', 'CT'].includes(showType)"
                :exercisesInfo="item"
                :openType="4"
                :exercisesListIndex="index+1">

            </exercises-c-t>
          </div>
          <!-- 提交 v-if="progressType=='NR'" -->
          <div v-if="progressType=='NR'"  class="submitExaminationReel" >
            <p @click="changeSubmit">确定</p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :visible.sync="appraiseScoreShow"
        :show-close="true"
        title="评价得分"
    >
      <div>
        <div class="appraiseScoreFlex">
          <div>
            <!--            <p>-->
            <!--              <span>测试得分:</span>-->
            <!--              <span class="ScoreColor">96分</span>-->
            <!--            </p>-->
            <span class="appraiseMr">评价:</span>
            <el-select v-model="Scorevalue" placeholder="请选择">
              <el-option
                  v-for="item in optionss"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="appriseSCoreSb">
            <p @click="GocheckPaperList">返回</p>
            <p @click="teacherCheckStdWork">提交</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  studentWorkDetail,
  teacherCheckWork,
  teacherCheckExaminationDetail,
  teacherCheckStdExercises,
  teacherCheckExaminationStdList, workStudentList,

} from "@/api";
import ExercisesTF from "@/components/exercisesList/exercisesTF";
import ExercisesSC from "@/components/exercisesList/exercisesSC";
import ExercisesMC from "@/components/exercisesList/exercisesMC";
import ExercisesFB from "@/components/exercisesList/exercisesFB";
import ExercisesSAQ from "@/components/exercisesList/exercisesSAQ";
import ExercisesCT from "@/components/exercisesList/exercisesCT";
export default {
  components: {ExercisesTF, ExercisesSC, ExercisesMC, ExercisesFB, ExercisesSAQ,ExercisesCT},
  data() {
    return {
      chooseRadio: "",
      chooseTFRadio: '选中且禁用',
      optionss: [
        {
          value: "A",
          label: "优",
        },
        {
          value: "B",
          label: "良",
        },
        {
          value: "C",
          label: "及格",
        },
        {
          value: "D",
          label: "不及格",
        },
      ],
      question: [
        {id: 1, name: "全部", type: 'all'},
        {id: 2, name: "单项选择题", type: 'SC'},
        {id: 3, name: "多项选择题", type: 'MC'},
        {id: 4, name: "判断题", type: 'TF'},
        {id: 5, name: "简答题", type: 'SAQ'},
        {id: 6, name: "填空题", type: 'FB'},
      ],
      vocationalQuestion: [
        {id: 1, name: "全部", type: 'all'},
        {id: 2, name: "单项选择题", type: 'SC'},
        {id: 3, name: "多项选择题", type: 'MC'},
        {id: 4, name: "判断题", type: 'TF'},
        {id: 5, name: "简答题", type: 'SAQ'},
        {id: 6, name: "填空题", type: 'FB'},
        {id: 7, name: "阅读理解", type: 'RC'},
        {id: 8, name: "完形填空", type: 'CT'},
      ],
      questionShow: 0,
      appraiseScoreShow: false,
      Scorevalue: "",
      courseworkId: "",
      queryStudentId: "",
      studentWorkDetail:{},
      stdDetailArr: [],
      checkList: [],
      workDetail: {
        name:''
      },
      CheckExaminationDetailArr:[],
      studentExamDetail:{},
      examPapersTestCenterName:'',
      totalScore:0,
      progressType:'',
      celerityCheckExamId:'',
      // 显示的类型
      showType: 'all'
    };
  },
  methods: {
    getLetter(index) {
      return String.fromCharCode(65 + index)
    },
    // 分类Tab
    changeQuestion(index, type) {
      this.questionShow = index;
      if(this.$route.query.celerityCheckExam){
        this.teacherCheckExamDetail()
      }else if(this.$route.query.celerityCheckWork){
        this.getWorkSteList()
      }else if(this.$route.query.courseworkId){
        // this.getWorkDetail()
        this.showType = type
      }else{
        this.showType = type
        // this.getTeacherCheckExaminationDetail()
      }
    },
    // 提交
    changeSubmit() {
      if(this.$route.query.celerityCheckExam){
        this.teacherCheckExamination();
      }else if(this.$route.query.celerityCheckWork){
        this.appraiseScoreShow = true;
      }else if(this.$route.query.courseexamId){
        this.teacherCheckExamination()
      }else{
        this.appraiseScoreShow = true;
      }
    },
    // 返回阅卷列表
    GocheckPaperList() {
      this.appraiseScoreShow = false
    },
    //  获取作业详情
    getWorkDetail() {
      let data = {
        courseworkId: this.courseworkId,
        queryStudentId: this.queryStudentId,
        page:1,
        size:200
      }
      if (this.questionShow === 1) {
        data['workType'] = 'SC'
      }
      if (this.questionShow === 2) {
        data['workType'] = 'MC'
      }
      if (this.questionShow === 3) {
        data['workType'] = 'TF'
      }
      if (this.questionShow === 4) {
        data['workType'] = 'SAQ'
      }
      if (this.questionShow === 5) {
        data['workType'] = 'FB'
      }
      studentWorkDetail(data).then(res => {
        let data = [];
        res.data.records.forEach((item)=>{
          data.push({
            ...item.exercisesInfo,
            answerVo: item.answerVo,
            workType: item.workType,
            courseworkItemTitle: item.courseworkItemTitle
          })
        })
        this.stdDetailArr = data
        this.stdDetailArr.forEach((item1,index1)=>{
          if(item1.answerVo){
            this.$set(this.stdDetailArr[index1],  "resultEvaluate",item1.answerVo.mComments);
            this.$set(this.stdDetailArr[index1],  "mResult",item1.answerVo.mResult);
          }
        })
      })
    },
    //  教师批改作业
    teacherCheckStdWork() {
      let checkExDataArr=[]
      this.$refs['std'].forEach((item, index)=>{
        if(item.getCorrection().item.id===item.getScoreData().item.id){
          let obj={}
          obj['courseworkId']=this.courseworkId;
          obj['id']=item.getCorrection().item.answerVo.id;
          obj['exercisesType']=item.getCorrection().item.exercisesType
          obj['mComments']=item.getCorrection().resultEvaluate
          obj['resultScore']=item.getScoreData().resultScore
          if(item.getmResultData){
            obj['mResult']=item.getmResultData().mResult
          }
          if(item.getFBCheckData){
            let mResultdata=[]
            let answerCheck=item.getFBCheckData().mResult
            answerCheck.forEach((item1,index1)=>{
              let answerCheckIndex=answerCheck.indexOf('error')
              if(answerCheckIndex!==-1&& item1=='error'){
                mResultdata.push(index1+1)
                obj['mResult']='error'
                obj['errorBody']=mResultdata.join(',')
              }else if(answerCheckIndex==-1 && item1=='correct'){
                obj['mResult']='correct'
              }
            })
          }
          checkExDataArr.push(obj)
        }})
        let data={
          homeWorkGrade:this.Scorevalue,
          studentId:this.$route.query.queryStudentId?this.$route.query.queryStudentId:this.studentWorkDetail.id,
          courseworkId:this.courseworkId,
          correctAnswerList:checkExDataArr
        };
        teacherCheckWork(data).then(res=>{
          if(res.code==0){
            this.$message({
              type: 'success',
              message: '批阅成功!'
            });
            if(this.$route.query.courseworkId){
              this.$router.push({path:'checkPaperList',query:{id:this.courseworkId,type:'work'}});
            }else{
              this.getWorkSteList()
            }
          }else{
            this.$message.error(res.msg)
          }
        })
    },
    getTeacherComment() {
      return this.$refs.std.getCorrection();
    },
  //  获取试卷详情
    getTeacherCheckExaminationDetail(){
      let data={
        examPapersTestCenterId:this.$route.query.courseexamId,
        userId:this.$route.query.queryExamStudentId,
        page:1,
        size:200
      };
      if (this.questionShow === 1) {
        data['exercisesType'] = 'SC'
      }
      if (this.questionShow === 2) {
        data['exercisesType'] = 'MC'
      }
      if (this.questionShow === 3) {
        data['exercisesType'] = 'TF'
      }
      if (this.questionShow === 4) {
        data['exercisesType'] = 'SAQ'
      }
      if (this.questionShow === 5) {
        data['exercisesType'] = 'FB'
      }
      if (this.questionShow === 6) {
        data['exercisesType'] = 'RC'
      }
      if (this.questionShow === 7) {
        data['exercisesType'] = 'CT'
      }
      teacherCheckExaminationDetail(data).then(res=>{
        this.CheckExaminationDetailArr=res.data.records;
      })
    },
  //  教师阅卷
    teacherCheckExamination(){
      // this.$refs['std'].forEach((item, index)=>{
      //   console.log(item.getCorrection());
      //   console.log(item.getScoreData());
      //   console.log(item.getmResultData());
      // });return;
      let checkExDataArr=[]
      this.$refs['std'].forEach((item, index)=>{
        if(item.getCorrection().item.id===item.getScoreData().item.id){
          let obj={}
          obj['examPapersItemId']=item.getCorrection().item.examPapersItemId;
          obj['id']=item.getCorrection().item.id;
          obj['exercisesType']=item.getCorrection().item.exercisesType
          if(item.getCorrection().resultEvaluate||item.getScoreData().resultScore){
            obj['resultEvaluate']=item.getCorrection().resultEvaluate
            obj['resultScore']=item.getScoreData().resultScore
            if(item.getmResultData){
              obj['mResult']=item.getmResultData().mResult
            }
          }else{
            obj['exercisesBody']=item.getCorrection().exercisesBody
          }
          if(item.getCorrection().exercisesBody&&item.getCorrection().exercisesBody.length){
            let mResultdata=[];
            let totalScore=0
            item.getCorrection().exercisesBody.forEach(bigItem=>{
              if(bigItem.resultScore==undefined){
                bigItem.resultScore=0
              }
              totalScore+=Number(bigItem.resultScore)
              if(bigItem.exercisesType=='FB'){
                bigItem.mResult.forEach((item1,index1)=>{
                  let answerCheckIndex=bigItem.mResult.indexOf('error')
                  if(answerCheckIndex!==-1 && item1=='error'){
                    mResultdata.push(index1+1)
                    bigItem['errorBody']=mResultdata.join(',')
                    bigItem['mResult']='error'
                  }else if(answerCheckIndex==-1 && item1=='correct'){
                    bigItem['mResult']='correct';
                    if(bigItem.errorBody){
                      delete bigItem.errorBody
                    }
                  }
                })
              }
            });
            obj['resultScore']=totalScore
          }
          if(item.getFBCheckData){
            let mResultdata=[]
            let answerCheck=item.getFBCheckData().mResult
            answerCheck.forEach((item1,index1)=>{
              let answerCheckIndex=answerCheck.indexOf('error')
              if(answerCheckIndex!==-1 && item1=='error'){
                mResultdata.push(index1+1)
                obj['errorBody']=mResultdata.join(',')
                obj['mResult']='error'
              }else if(answerCheckIndex==-1 && item1=='correct'){
                obj['mResult']='correct'
              }
            })
          }
          checkExDataArr.push(obj)
        }
      })
      let data={
        examPapersTestCenterId:this.$route.query.courseexamId?this.$route.query.courseexamId:this.examPapersTestCenterId,
        studentId:this.$route.query.queryExamStudentId?this.$route.query.queryExamStudentId:this.celerityCheckId,
        userAnswerVoList:checkExDataArr
      };
      teacherCheckStdExercises(data).then(res=>{
        if(res.code==0){
          this.$message({
            type: 'success',
            message: '批阅成功!'
          });
          if(this.$route.query.courseexamId){
            this.$router.push({path:'checkPaperList',query:{id:this.$route.query.courseexamId,type:'exam'}})
          }else{
            this.getCelerityCheckExam();
          }
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  快速批阅学生列表
    getCelerityCheckExam(){
      let data={
        examPapersTestCenterId:this.celerityCheckExamId,
        page:1,
        size:200,
        progressType:'NR'
      };
      teacherCheckExaminationStdList(data).then(res=>{
        if(res.code==0&&res.data.records&&res.data.records.length){
          this.studentExamDetail.examPapersStudentInfo=res.data.records[0].examPapersStudentInfo
          this.examPapersTestCenterId=res.data.records[0].examPapersStudentInfo.examPapersTestCenterId;
          this.celerityCheckId=res.data.records[0].examPapersStudentInfo.userId;
          this.studentExamDetail.name=res.data.records[0].examPapersStudentInfo.userName;
          this.studentExamDetail.studentNumber=res.data.records[0].examPapersStudentInfo.userTelNumber;
          this.examPapersTestCenterName=res.data.records[0].examPapersStudentInfo.examPapersTestCenterName
          this.teacherCheckExamDetail()
        }else if(!res.data.records.length){
          this.$message('没有试卷可批阅了~~')
        }
      })
    },
  //  快速批阅试卷详情
    teacherCheckExamDetail(){
      let data={
        examPapersTestCenterId:this.examPapersTestCenterId,
        userId:this.celerityCheckId,
        page:1,
        size:200
      };
      if (this.questionShow === 1) {
        data['exercisesType'] = 'SC'
      }
      if (this.questionShow === 2) {
        data['exercisesType'] = 'MC'
      }
      if (this.questionShow === 3) {
        data['exercisesType'] = 'TF'
      }
      if (this.questionShow === 4) {
        data['exercisesType'] = 'SAQ'
      }
      if (this.questionShow === 5) {
        data['exercisesType'] = 'FB'
      }
      if (this.questionShow === 6) {
        data['exercisesType'] = 'RC'
      }
      if (this.questionShow === 7) {
        data['exercisesType'] = 'CT'
      }
      teacherCheckExaminationDetail(data).then(res=>{
        this.CheckExaminationDetailArr=res.data.records;
      })
    },
    //获取作业学生列表
    getWorkSteList(){
      let data={
        id:this.$route.query.id,
        page:1,
        size:5,
        queryStudentProgressType:'NR'
      }
      workStudentList(data).then(res=>{
        this.studentWorkDetail=res.data.records[0];
        if(res.code===0&&res.data.records&&res.data.records.length){
          let data = {
            courseworkId: this.$route.query.id,
            queryStudentId: res.data.records[0].id
          }
          if (this.questionShow === 1) {
            data['workType'] = 'SC'
          }
          if (this.questionShow === 2) {
            data['workType'] = 'MC'
          }
          if (this.questionShow === 3) {
            data['workType'] = 'TF'
          }
          if (this.questionShow === 4) {
            data['workType'] = 'SAQ'
          }
          if (this.questionShow === 5) {
            data['workType'] = 'FB'
          }
          studentWorkDetail(data).then(res => {
            let data = [];
            res.data.records.forEach((item)=>{
              data.push({
                ...item.exercisesInfo,
                answerVo: item.answerVo,
                workType: item.workType,
                courseworkItemTitle: item.courseworkItemTitle
              })
            })
            this.stdDetailArr = data
            this.stdDetailArr.forEach((item1,index1)=>{
              if(item1.answerVo){
                this.$set(this.stdDetailArr[index1],  "resultEvaluate",item1.answerVo.mComments);
                this.$set(this.stdDetailArr[index1],  "mResult",item1.answerVo.mResult);
              }
            })
          })
        }else if(!res.data.records.length){
          this.$message('没有试卷可批阅了~~')
        }
      })
    },
  },
  mounted() {
    this.$other.$emit('setIndexNav',1);
    document.documentElement.scrollTop = 0;
    if(this.$route.query.celerityCheckExam){
      this.celerityCheckExamId = this.$route.query.id
      this.progressType=this.$route.query.progressType;
      this.getCelerityCheckExam()
    }else if(this.$route.query.celerityCheckWork){
      this.courseworkId = this.$route.query.id;
      this.workDetail.name=this.$route.query.title
      this.getWorkSteList()
      this.progressType=this.$route.query.progressType;
    }else if(this.$route.query.courseworkId){
      this.courseworkId = this.$route.query.courseworkId;
      this.queryStudentId = this.$route.query.queryStudentId;
      this.progressType=this.$route.query.progressType
      this.getWorkDetail()
      this.studentWorkDetail = JSON.parse(sessionStorage.getItem('studentWorkDetail'))
      this.workDetail = JSON.parse(sessionStorage.getItem('workDetail'))
    }else{
      this.getTeacherCheckExaminationDetail()
      this.studentExamDetail = JSON.parse(sessionStorage.getItem('studentExamDetail'))
      this.examPapersTestCenterName=this.studentExamDetail.examPapersStudentInfo.examPapersTestCenterName;
      this.studentExamDetail.name=this.studentExamDetail.examPapersStudentInfo.name;
      this.studentExamDetail.telNumber=this.studentExamDetail.examPapersStudentInfo.userTelNumber;
      this.studentExamDetail.studentNumber=this.studentExamDetail.examPapersStudentInfo.studentNumber;
      this.totalScore=this.$route.query.totalScore;
      this.progressType=this.$route.query.progressType;
    }

  },
  updated() {
    MathJax.typesetPromise();
  }
};
</script>
<style scoped>
@import "../../public/css/modifyPaper.css";
</style>
